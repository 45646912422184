import isMobilePhone from "validator/lib/isMobilePhone";

/**
 * Requires valid phone number. Allows empty string.
 */
export const phoneNumberValidator = (value: string) => {
  const errors = [];
  if (value && !isMobilePhone(value)) {
    errors.push(`${value} is not a valid phone number`);
  }
  return errors;
};
