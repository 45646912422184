// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._i-0 {\n  display: flex;\n  flex-direction: column;\n}\n\n._i-1 {\n  font-weight: bold;\n  font-size: var(--redo-body-small-text-size);\n}\n\n.MuiPopper-root {\n  z-index: 1500;\n}\n\n._i-2 {\n  color: var(--redo-colors-text-text-primary-900);\n  background-color: var(--redo-colors-background-bg-primary);\n  border-radius: 10px;\n  border: var(--redo-border-width) solid\n    var(--redo-colors-border-border-primary);\n  display: flex;\n  justify-content: space-between;\n  padding: 12px;\n  cursor: pointer;\n  align-items: center;\n  font-size: var(--redo-body-medium-text-size);\n  transition: border var(--redo-state-duration);\n  gap: var(--redo-spacing);\n  line-height: 100%;\n}\n\n._i-2._i-3 {\n    padding: 8px 12px;\n  }\n\n._i-2._i-4 {\n    background-color: var(--redo-colors-background-bg-primary);\n    border: var(--redo-border-width) solid\n      var(--redo-colors-border-border-primary);\n  }\n\n._i-2:hover {\n    border-color: var(--redo-colors-border-border-primary_hover);\n  }\n\n._i-2:disabled {\n    text-decoration: line-through;\n    color: var(--redo-colors-text-text-disabled);\n  }\n\n._i-5 {\n  transition: opacity 250ms;\n  box-shadow: 0px 14px 40px rgba(24, 24, 24, 0.09);\n  margin: var(--redo-spacing);\n}\n\n._i-5._i-6 {\n  background-color: var(--redo-colors-background-bg-primary);\n}\n\n._i-5._i-7,\n._i-5._i-8 {\n  /* opacity: 0; */\n}\n\n._i-9 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px;\n  cursor: pointer;\n}\n\n._i-a {\n  --size: 16px;\n  color: var(--redo-colors-foreground-fg-brand-primary-600);\n  height: var(--size);\n  width: var(--size);\n}\n\n._i-b {\n  color: var(--redo-colors-foreground-fg-quarterary-500);\n  width: var(--redo-icon-medium-size);\n  height: var(--redo-icon-medium-size);\n  transition: color var(--redo-state-duration);\n  flex-shrink: 0;\n}\n\n._i-c {\n  border: 1px solid var(--redo-colors-border-border-secondary);\n}\n", ""]);
// Exports
export var container = "_i-0";
export var label = "_i-1";
export var select = "_i-2";
export var reducedPadding = "_i-3";
export var brand = "_i-4";
export var dropdown = "_i-5";
export var background = "_i-6";
export var enter = "_i-7";
export var exitActive = "_i-8";
export var option = "_i-9";
export var check = "_i-a";
export var selectIcon = "_i-b";
export var line = "_i-c";
export default ___CSS_LOADER_EXPORT___;
