// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._p-0 {\n  z-index: 2147483006 !important; /* 1 higher than RedoDropdown */\n}\n\n._p-1 {\n  font-size: var(--size-body-xs);\n  line-height: 18px;\n  color: var(--redo-colors-text-text-quarterary-500);\n}\n\n._p-2 {\n  text-align: left;\n}\n", ""]);
// Exports
export var popper = "_p-0";
export var subtitle = "_p-1";
export var leftAlign = "_p-2";
export default ___CSS_LOADER_EXPORT___;
