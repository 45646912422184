import STYLE_MANAGER from "../../style-manager";

import createTheme from "@mui/material/styles/createTheme";
import { createRoot } from "react-dom/client";
import { AuthenticatedShopifyExtensionClientProvider } from "../../client/authenticated-client-context";
import { RedoRoot } from "../../root";
import { ContactForm } from "./contact-form";

void import("./styles.css");

function initialize() {
  const target = document.getElementById("redo-contact-form-target");
  if (!target) {
    return;
  }

  const shadowHost = document.createElement("div");

  shadowHost.style.display = "flex";
  shadowHost.style.justifyContent = "center";
  shadowHost.style.width = "80%";

  target.appendChild(shadowHost);

  const shadowRoot = shadowHost.attachShadow({ mode: "open" });
  STYLE_MANAGER.register(shadowRoot);

  const root = createRoot(shadowRoot);

  // Create a second shadow host for MUI components
  const muiShadowHost = document.createElement("div");
  muiShadowHost.id = "redo-mui-container";
  document.body.appendChild(muiShadowHost);

  // Create empty element to prevent shadow host from being hidden.
  const emptyElement = document.createElement("p");
  muiShadowHost.appendChild(emptyElement);

  const muiShadowRoot = muiShadowHost.attachShadow({ mode: "open" });
  STYLE_MANAGER.register(muiShadowRoot);

  const muiRoot = document.createElement("div");
  muiShadowRoot.appendChild(muiRoot);

  const muiTheme = createTheme({
    components: {
      MuiPopper: { defaultProps: { container: muiRoot } },
      MuiModal: { defaultProps: { container: muiRoot } },
    },
  });
  root.render(
    <RedoRoot
      extensionShopifyClient={undefined}
      muiTheme={muiTheme}
      storeUrl={
        window.redoStorefront?.storeUrl ??
        window.Shopify?.shop ??
        window.Shopify?.Checkout?.apiHost
      }
    >
      <AuthenticatedShopifyExtensionClientProvider
        baseUrl={
          window.redoStorefront?.env?.REDO_SHOPIFY_SERVER_URL ??
          process.env.REDO_SHOPIFY_SERVER_URL!
        }
        storeUrl={Shopify?.shop}
      >
        <ContactForm />
      </AuthenticatedShopifyExtensionClientProvider>
    </RedoRoot>,
  );
}

initialize();
