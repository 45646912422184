/**
 * @file
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-9 RFC 9110 9 Methods}
 */

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-9 RFC 9110 9 Methods}
 */
export class Method {
  constructor(readonly name: string) {}

  toString() {
    return this.name;
  }
}

/**
 * GET
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-9.3.1 RFC 9110 9.3.1 GET}
 */
export const GetMethod = new Method("GET");

/**
 * HEAD
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-9.3.2 RFC 9110 9.3.2 HEAD}
 */
export const HeadMethod = new Method("HEAD");

/**
 * POST
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-9.3.3 RFC 9110 9.3.3 POST}
 */
export const PostMethod = new Method("POST");

/**
 * PUT
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-9.3.4 RFC 9110 9.3.4 PUT}
 */
export const PutMethod = new Method("PUT");

/**
 * PATCH
 * @see {@link https://www.rfc-editor.org/rfc/rfc5789}
 */
export const PatchMethod = new Method("PATCH");

/**
 * DELETE
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-9.3.5 RFC 9110 9.3.5 DELETE}
 */
export const DeleteMethod = new Method("DELETE");

/**
 * CONNECT
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-9.3.6 RFC 9110 9.3.6 CONNECT}
 */
export const ConnectMethod = new Method("CONNECT");

/**
 * OPTIONS
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-9.3.7 RFC 9110 9.3.7 OPTIONS}
 */
export const OptionsMethod = new Method("OPTIONS");

/**
 * TRACE
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-9.3.8 RFC 9110 9.3.8 TRACE}
 */
export const TraceMethod = new Method("TRACE");
