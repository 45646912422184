// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2-0 {\n  width: 100%;\n}\n\n._2-1 {\n  margin-left: auto;\n  width: 100px;\n  padding: none;\n}\n\n._2-2 {\n  border: 1px solid var(--redo-colors-border-border-secondary);\n}\n\n._2-3 {\n  border: 1px solid var(--redo-colors-border-border-secondary);\n  width: 40px;\n  height: 40px;\n  color: var(--redo-colors-foreground-fg-secondary-700);\n}\n\n._2-3:hover {\n    background-color: #f0f0f0;\n    cursor: pointer;\n  }\n\n._2-4 {\n  display: none;\n}\n._2-5 {\n  display: flex;\n  flex-direction: row;\n  gap: calc(var(--redo-spacing) * 2);\n  overflow-x: auto;\n  padding: 12px 12px 16px 0;\n  flex-wrap: wrap;\n}\n._2-5 div:hover ._2-6 {\n      display: flex;\n    }\n\n._2-7 {\n  position: relative;\n  width: 48%;\n}\n\n._2-7 ._2-6 {\n    position: absolute;\n    top: -12px;\n    right: -12px;\n    background-color: var(--redo-surface-danger-light-color);\n    color: var(--redo-colors-base-black);\n    border-radius: 50%;\n    cursor: pointer;\n    width: 24px;\n    height: 24px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    display: none;\n  }\n\n._2-7 ._2-6 svg {\n      width: 16px !important;\n      height: 16px !important;\n    }\n\n._2-8 {\n  text-align: center;\n  color: var(--redo-colors-text-text-secondary-700);\n  border-width: 2px;\n  border-style: solid;\n}\n\n._2-9 {\n  background-color: var(--redo-colors-success-200);\n  border-color: var(--redo-colors-success-300);\n}\n\n._2-a {\n  background-color: var(--redo-colors-error-200);\n  border-color: var(--redo-colors-error-300);\n}\n", ""]);
// Exports
export var form = "_2-0";
export var submitButton = "_2-1";
export var fileUploadContainer = "_2-2";
export var uploadIconContainer = "_2-3";
export var fileInput = "_2-4";
export var fileCarousel = "_2-5";
export var removeFileButton = "_2-6";
export var draftAttachmentContainer = "_2-7";
export var submittedMessageContainer = "_2-8";
export var successMessageContainer = "_2-9 _2-8";
export var errorMessageContainer = "_2-a _2-8";
export default ___CSS_LOADER_EXPORT___;
